import {
    CardDoubleStack,
    countStack,
    isBomb,
    stacksAreEqual,
} from "../../cards";
import { AllPlayers, PlayerIndex, PlayerProfile } from "../player/player";
import {
    findPlayerWithPosition,
    leftOpponentOf,
    rightOpponentOf,
} from "../player/position";
import { PlayPhasePlayer } from "../playerstate";
import { PlayPhaseState, PlayPhaseTag } from "../state/state";
import { GameStateSpectatorView } from "./spectatorview";
import { GameStateView } from "./stateview";

export type ViewOfGame = GameStateView | GameStateSpectatorView;

export function aBombWasJustPlayed(
    oldState: ViewOfGame,
    newState: ViewOfGame
): boolean {
    if (newState.phase !== PlayPhaseTag) {
        return false;
    }
    const newTrick = newState.currentTrick;
    if (newTrick.stacks.length < 1) {
        return false;
    }
    const newTopTrick = newTrick.stacks[newTrick.stacks.length - 1];
    if (!isBomb(newTopTrick)) {
        return false;
    }
    if (oldState.phase !== PlayPhaseTag) {
        return false;
    }
    const oldTrick = oldState.currentTrick;
    if (oldTrick.stacks.length + 1 !== newTrick.stacks.length) {
        return false;
    }
    for (let i = 0; i < oldTrick.stacks.length; ++i) {
        if (!stacksAreEqual(oldTrick.stacks[i], newTrick.stacks[i])) {
            return false;
        }
    }
    return true;
}

const wentOut = (player: PlayPhasePlayer): boolean => {
    return countStack(player.inHand) === 0;
};

export const gameOver = (players: PlayPhaseState["players"]): boolean => {
    const playersLeft = players
        .map((p) => !wentOut(p))
        .reduce((acc, b) => (b ? acc + 1 : acc), 0);
    if (playersLeft <= 1) {
        return true;
    }
    const profiles = players.map((p) => p.profile);
    const npos = findPlayerWithPosition("North", profiles);
    const spos = findPlayerWithPosition("South", profiles);
    const epos = findPlayerWithPosition("East", profiles);
    const wpos = findPlayerWithPosition("West", profiles);
    if (wentOut(players[npos]) && wentOut(players[spos])) {
        return true;
    }
    if (wentOut(players[epos]) && wentOut(players[wpos])) {
        return true;
    }
    return false;
};

const dragonOnTop = (trick: CardDoubleStack) => {
    if (trick.stacks.length === 0) {
        return false;
    }
    const topOfTrick = trick.stacks[trick.stacks.length - 1];
    if (topOfTrick.cards.length !== 1) {
        return false;
    }
    return topOfTrick.cards[topOfTrick.cards.length - 1] === "Dragon";
};

export const youPlayedLast = (
    trick: CardDoubleStack,
    you: PlayerIndex
): boolean => {
    if (trick.stacks.length === 0) {
        return false;
    }
    const topOfTrick = trick.stacks[trick.stacks.length - 1];
    return topOfTrick.player === you;
};

export const opponentPlayedLast = (
    trick: CardDoubleStack,
    players: AllPlayers<PlayerProfile>,
    you: PlayerIndex
): boolean => {
    if (trick.stacks.length === 0) {
        return false;
    }
    const topOfTrick = trick.stacks[trick.stacks.length - 1];
    const lastPlayer = topOfTrick.player;
    if (you === leftOpponentOf(lastPlayer, players)) {
        return true;
    }
    if (you === rightOpponentOf(lastPlayer, players)) {
        return true;
    }
    return false;
};

export const canTakeTrick = (
    trick: CardDoubleStack,
    players: AllPlayers<PlayerProfile>,
    you: PlayerIndex
): boolean => {
    if (trick.stacks.length === 0) {
        return false;
    }
    if (dragonOnTop(trick)) {
        return opponentPlayedLast(trick, players, you);
    } else {
        return youPlayedLast(trick, you);
    }
};
